import React, {useContext, useEffect, useState} from 'react';
import {formatTimeOnly} from "../../../../functions/formatting/formatDate";
import {fetchBlockedEndpoints} from "../../../../functions/fetchBlockedEndpoints";
import ImageMfl from "../../viewMedia/ImageMfl";
import FormatAddress from "../../../../functions/formatting/formatAddress";
import mainContext from "../../../contexts/mainContext";
import {fetchUserLevel} from "../../../../functions/fetchUserLevel";

const JCaterpillarTooltip = ({jHover}) => {

    const {journeys} = useContext(mainContext)

    const [style, setStyle] = useState();
    const [caretStyle, setCaretStyle] = useState();

    useEffect(() => {
        if (jHover){

            const preStyle = {width: 620 + "px", position: 'absolute', background: 'var(--tooltip-bg)', zIndex: 1000000, top: '50px', borderRadius: '3px',
            left: '-170px', padding: '10px'}

            const caret = {width: "10px", height: '10px' ,position: 'absolute', background: 'var(--tooltip-bg)', zIndex: 1000, top: '46px',
            transform: 'rotate(45deg)', left: jHover.j.startPixel + (jHover.j.endPixel / 2) + 5 + 'px', borderRadius: '1px'}


            setCaretStyle(caret)
            setStyle(preStyle)
        } else {
            setCaretStyle(null)
            setStyle(null);
        }
    }, [jHover])

    return (
        <React.Fragment>
            {style ?
                <React.Fragment>
                    <div style={caretStyle}></div>
                    <div style={style}>
                        <div style={{color: 'white', display: 'flex'}}>

                            <div style={{
                                display: 'flex',
                                width: '500px',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                            }}>
                                <div style={{fontWeight: 'bold', fontSize: '1.3rem'}}>
                                    Departed:&nbsp;{formatTimeOnly(new Date(jHover?.j?.startTime))}
                                </div>

                                {jHover?.j?.startAddress?.addressData &&
                                    <FormatAddress data={jHover?.j} type={'start'}/>
                                }

                                {jHover?.index > 0 && jHover?.j?.completed !== null && jHover?.j?.completed !== undefined && !fetchBlockedEndpoints().includes('view-snapshot') &&
                                    <div style={{width: '225px', paddingTop: '15px'}}>
                                        <ImageMfl file={journeys[(jHover?.index - 1)]?.endJpeg}  size="sm"/>
                                    </div>
                                }

                                {jHover?.index === 0 && jHover?.lastJourney?.endJpeg?.length > 1 && journeys?.length > 1 && !fetchBlockedEndpoints().includes('view-snapshot') &&
                                    <div style={{width: '225px', paddingTop: '15px'}}>
                                        <ImageMfl file={jHover?.lastJourney?.endJpeg}  size="sm"/>
                                    </div>
                                }

                            </div>
                            <div style={{
                                display: 'flex', justifyContent: 'center', paddingRight: '10px',
                                flexDirection: 'column', minWidth: '150px', paddingLeft: '10px'
                            }}>

                                <div>
                                    <div className="arrow-long-right"/>
                                </div>

                                {jHover?.j?.driver && jHover?.j?.completed !== null && jHover?.j?.completed !== undefined &&
                                    <div>Driver:&nbsp;{jHover?.j?.driver}</div>
                                }

                                {jHover?.j?.duration &&
                                    <div style={{width: '100%'}}>Duration:&nbsp;{jHover?.j.duration}</div>
                                }

                                {jHover?.j?.distance &&
                                    <div>Distance:&nbsp;{(jHover?.j.distance).toFixed(2)}&nbsp;miles</div>
                                }

                                {fetchUserLevel() === 'superuser' && jHover?.j?.fatigueScore &&
                                    <div style={{width: '100%'}}>Fatigue:&nbsp;{jHover?.j?.fatigueScore}</div>
                                }


                            </div>
                            <div style={{
                                display: 'flex',
                                width: '500px',
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                            }}>
                                {jHover?.j?.completed !== null && jHover?.j?.completed !== undefined ?
                                    <React.Fragment>
                                        <div style={{fontWeight: 'bold', fontSize: '1.3rem'}}>
                                            Arrived:&nbsp;{formatTimeOnly(new Date(jHover?.j?.endTime))}
                                        </div>

                                        {jHover?.j?.endAddress?.addressData &&
                                            <FormatAddress data={jHover?.j} type={'end'}/>
                                        }

                                        {jHover?.j?.endJpeg && !fetchBlockedEndpoints().includes('view-snapshot') &&
                                            <div style={{width: '225px', paddingTop: '15px'}}>
                                                <ImageMfl file={jHover?.j?.endJpeg} size="sm"/>
                                            </div>
                                        }

                                    </React.Fragment>
                                    :
                                    <div style={{fontWeight: 'bold', fontSize: '1.3rem'}}>
                                        In Progress: {formatTimeOnly(new Date())}&nbsp;
                                    </div>
                                }
                            </div>


                        </div>
                    </div>
                </React.Fragment>

                :
                <React.Fragment></React.Fragment>
            }
        </React.Fragment>
    )
}
export default JCaterpillarTooltip