import React, {useContext, useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowRotateRight,
    faPowerOff,
    faVideo,
    faRouter,
    faClock,
    faNetworkWired,
    faBarcode,
    faTabletRugged,
    faDial,
    faSimCard,
    faCalendar
}
    from "@fortawesome/pro-regular-svg-icons";
import {uCaseFirst} from "../../../../../functions/formatting/uCaseFirst";
import mainContext from "../../../../contexts/mainContext";
import {modelNumberDisplay} from "../../../../../functions/formatting/displayModelNumber";
import ImageMfl from "../../../viewMedia/ImageMfl";
import fetchEventById from "../../../../../api/events/fetchEventById";
import {Tooltip} from "primereact/tooltip";
import {Button} from "primereact/button";
import {ConfirmPopup} from "primereact/confirmpopup";
import {formatDateNotificationsTable, formatDateOnly} from "../../../../../functions/formatting/formatDate";
import postDisableDevice from "../../../../../api/device/postDisableDevice";

const DeviceSettingsTable = ({leftCol, rightCol}) => {

    const {deviceDetails} = useContext(mainContext);
    const [visible, setVisible] = useState(false);
    const [mode, setMode] = useState(deviceDetails?.commissioning?.commissionStatus);

    const buttonEl = useRef();

    const confirmMessage = "Are you sure you want to disable this device? Once disabled it can only be reactivated via support."



    const overRunToHours = (over) => {
        const hours = Math.floor(over / 60);
        const minutes = over % 60;

        let str = ''

        if (hours === 1){
            str += hours + ' hour'
        } else if (hours > 1){
            str += hours + ' hours'
        }

        if (minutes > 0){
            str += ' ' + minutes + ' minutes'
        }

        return str
    }

    const disableDevice = async () => {
        const msg = `Device has been disabled by ${localStorage.getItem('email')} at ${formatDateNotificationsTable(new Date())}`;

        await postDisableDevice({
            email: localStorage.getItem('email'),
            dn: deviceDetails?.dn,
            message: msg
        });

        setMode('disabled')

    }



    return (
        <React.Fragment>
            <table style={{width: '100%', fontSize: '14px'}}>
                <tbody>
                <tr>
                    <th style={leftCol}>
                        <FontAwesomeIcon icon={faTabletRugged}/>
                        &nbsp;Device number:
                    </th>
                    <td style={rightCol}>{deviceDetails?.dn}</td>
                </tr>
                <tr>
                    <th style={leftCol}>
                        <FontAwesomeIcon icon={faDial}/>
                        &nbsp;Device Mode:
                    </th>
                    <td style={rightCol}>

                        <span className="modeTooltip">
                            {uCaseFirst(mode)}
                        </span>

                        {deviceDetails?.commissioning?.commissionStatus === 'active' &&
                            <Tooltip target=".modeTooltip" autoHide={false}>
                                <div className="flex align-items-center">


                                    <Button label="Disable" style={{background: 'rgba(255, 0, 36, .9)', color: 'white'}}
                                            onClick={(e) => setVisible(true)}/>

                                </div>
                            </Tooltip>
                        }


                        <ConfirmPopup target={buttonEl.current} visible={visible} onHide={() => setVisible(false)}
                                      message={confirmMessage} icon="pi pi-exclamation-triangle" accept={disableDevice}
                                      reject={() => setVisible(false)}/>


                    </td>
                </tr>
                <tr>
                    <th style={leftCol}>
                        <FontAwesomeIcon icon={faSimCard}/>
                        &nbsp;SIM number:
                    </th>
                    <td style={rightCol}>{deviceDetails?.deviceSIM}</td>
                </tr>
                <tr>
                    <th style={leftCol}>
                        <FontAwesomeIcon icon={faRouter}/>
                        &nbsp;Model number:
                    </th>
                    <td style={rightCol}>{modelNumberDisplay(deviceDetails?.device)}</td>
                </tr>
                <tr>
                    <th style={leftCol}>
                        <FontAwesomeIcon icon={faClock}/>
                        &nbsp;Timezone:
                    </th>
                    <td style={rightCol}>{deviceDetails?.timezone}</td>
                </tr>
                <tr>
                    <th style={leftCol}>
                        <FontAwesomeIcon icon={faBarcode}/>
                        &nbsp;Serial number:
                    </th>
                    <td style={rightCol}>{deviceDetails?.deviceSerial}</td>
                </tr>
                <tr>
                    <th style={leftCol}>
                        <FontAwesomeIcon icon={faCalendar}/>
                        &nbsp;Commission date:
                    </th>
                    <td style={rightCol}>{formatDateOnly(deviceDetails?.commissioning?.installDate)}</td>
                </tr>
                <tr>
                    <th style={leftCol}>
                        <FontAwesomeIcon icon={faNetworkWired}/>
                        &nbsp;API Connections:
                    </th>
                    <td style={rightCol}>
                        {deviceDetails?.mflGroup ? deviceDetails?.mflGroup : 'Fleet Focus'}
                    </td>
                </tr>
                <tr>
                    <th style={leftCol}>
                        <FontAwesomeIcon icon={faPowerOff}/>
                        &nbsp;Standby duration:
                    </th>
                    <td style={rightCol}>{overRunToHours(deviceDetails?.overrun)}</td>
                </tr>
                <tr>
                    <th style={leftCol}>
                        <FontAwesomeIcon icon={faArrowRotateRight}/>
                        &nbsp;Recording overrun:
                    </th>
                    <td style={rightCol}>{overRunToHours(deviceDetails?.overrunRecording)}</td>
                </tr>

                {deviceDetails?.cameras.map((cam, i) => {
                    return (
                        <tr key={i}>
                            <th style={leftCol}>
                                <FontAwesomeIcon icon={faVideo}/>
                                &nbsp;{cam.camPosition} camera recording quality:
                            </th>
                            <td style={rightCol}>{uCaseFirst(cam.camConfig.replace(/([a-z0-9])([A-Z])/g, '$1 $2'))}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </React.Fragment>
    )
}
export default DeviceSettingsTable
