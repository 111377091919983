import React, {useContext, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import Map2 from "../components/mainComponents/panelContent/map/Map2";
import fetchVehicles from "../api/fetchVehicles";
import Aside from "../components/mainComponents/vehicleList/aside";
import fetchJourneys from "../api/journeys/fetchJourneys";
import FetchJourney from "../api/journeys/fetchJourney";  //list
import './MainParent.css';
import fetchReverseGeocode from "../api/fetchReverseGeocode";
import MainModalParent from "../components/mainComponents/modals/mainModalParent";
import fetchDeviceDetails from "../api/device/fetchDeviceDetails";
import Reports from "../components/mainComponents/panelContent/reports/reports";
import {dateString, formatDate, formatDateForVehicles} from "../functions/formatting/formatDate";
import fetchEventById from "../api/events/fetchEventById";
import fetchCalendar from "../api/device/fetchCalendar";
import indexContext from "../components/contexts/indexContext";
import mainContext from "../components/contexts/mainContext";
import Grid from "../components/mainComponents/panelContent/grid/grid";
import Overlay from "../components/mainComponents/overlay";
import {useSwipeable} from "react-swipeable";
import {isStarWars} from "../functions/isStarWars";
import fetchEventsVideoFile from "../api/events/fetchEventsVideoFile";
import fetchLastestSnapshot from "../api/media/fetchLatestSnapshot";
import Places from "../components/mainComponents/panelContent/places/places";
import {addData, findDataByKey, getStoreData, updateData} from "../functions/db";
import {Toast} from "primereact/toast";



const MainParent = () => {

    let { urlQuery } = useParams();


    const {handlePageToShow, pageToShow, handleRefreshNotifications, showSidebar, showShortJourneys,
        handleShowSidebar, winWidth, handleShowSplashScreen, fleets} = useContext(indexContext)

    const [vehicles, setVehicles] = useState(null);
    const [vehiclesFilter, setVehiclesFilter] = useState(null);
    const [vehicle, setVehicle] = useState(null);
    const [deviceDetails, setDeviceDetails] = useState(null);
    const [journeys, setJourneys] = useState(null);
    const [journey, setJourney] = useState(null);
    const [lastJourney, setLastJourney] = useState(null);
    const [event, setEvent] = useState(null);
    const [date, setDate] = useState(new Date());
    const [sideBarWidthPx, setSideBarWidthPx] = useState(showSidebar ? '0px' : '-300px');
    const [displayEventClickModal, setDisplayEventClickModal] = useState(false);
    const [displayTrackClickModal, setDisplayTrackClickModal] = useState(false);
    const [displayLivestreamModal, setDisplayLivestreamModal] = useState(false);
    const [displayStoppedClickModal, setDisplayStoppedClickModal] = useState(false);
    const [displayDeviceDetailsModal, setDisplayDeviceDetailsModal] = useState(false);
    const [displayVideoModal, setDisplayVideoModal] = useState(false);
    const [lastMapCenter, setLastMapCenter ] = useState(null);
    const [fleetsToShow, setFleetsToShow] = useState(new Set([]));
    const [videoToShow, setVideoToShow ] = useState(null);
    const [calendar, setCalendar] = useState();
    const [disableDateChange, setDisableDateChange] = useState(false);
    const [trueLocation, setTrueLocation] = useState();
    const [dateRange, setDateRange] = useState();
    const [reportType, setReportType] = useState();



    const [vehicleFilterParams, setVehicleFilterParams] = useState({
        fleetsToShow: new Set([]),
        drivingStatus: new Set(['driving', 'parked', 'idling']),
        search: "",
        chips: new Set([])});



    const toast = useRef();

    let interval;


    const handleDateRange = (val) => setDateRange(val)
    const handleReportType = (val) => setReportType(val);
    const handleTrueLocation = (val) => setTrueLocation(val)
    const handleDisableDateChange = (val) => setDisableDateChange(val)
    const handleFleetsToShow = (val) => setFleetsToShow(val);
    const handleDeviceDetails = (val) => { setDeviceDetails(val)};
    const handleVehicleFilter = (val) => { setVehiclesFilter(val);}
    const handleVehicleFilterParams = (val) => setVehicleFilterParams(val)



    const handleVideoToShow = async (video) => {
        await setVideoToShow(video);
        const split = video.file.split("_")
        const details = await fetchDeviceDetails({properties:{dn : split[0]}});
        setDeviceDetails(details[0])
        openModal('displayVideoModal');
    }


    const modalFunctionMap = {
        'displayEventClickModal': setDisplayEventClickModal,
        'displayTrackClickModal': setDisplayTrackClickModal,
        'displayLivestreamModal': setDisplayLivestreamModal,
        'displayStoppedClickModal': setDisplayStoppedClickModal,
        'displayDeviceDetailsModal': setDisplayDeviceDetailsModal,
        'displayVideoModal': setDisplayVideoModal
    }


    const hideAllModals = () => {
        modalFunctionMap['displayEventClickModal'](false);
        modalFunctionMap['displayTrackClickModal'](false);
        modalFunctionMap['displayStoppedClickModal'](false);
        modalFunctionMap['displayLivestreamModal'](false);
        modalFunctionMap['displayDeviceDetailsModal'](false);
        modalFunctionMap['displayVideoModal'](false);
    }


    const openModal = (name) => {
        if(name === 'displayEventClickModal' && displayTrackClickModal){
            modalFunctionMap[`displayTrackClickModal`](false);
        }
        modalFunctionMap[`${name}`](true);
    }

    const worker = new Worker(new URL('./updateDbWorker.js', import.meta.url));

    const refreshVehicles = async () => {


        const r = await fetchVehicles();
        await setVehicles(r);


        worker.postMessage({ type: 'add', r});
        worker.onmessage = (answer) => {
            console.log(answer.data);
        };
        worker.onerror = (err) => console.log(err);

        if (!interval){
            interval = setInterval(async () => {
                const r = await fetchVehicles({lastModified: formatDateForVehicles(new Date())});
                worker.postMessage({ type: 'update', r});
                worker.onmessage = (answer) => {};
                worker.onerror = (err) => console.log(err);

            }, 60000);
        }





        let device;


        if (urlQuery){
            window.history.replaceState(null, "New Page Title", "/");

            const splitQry = urlQuery.split(',')
            if (splitQry?.[1]){
                device = r.features.filter(v => v.properties.dn === splitQry?.[1])
                if (device?.length > 0){
                    await handleVehicle(device[0]);
                    await new Promise(r => setTimeout(r, 500));
                }
            }

           if(splitQry?.[0] === 'events' ){
               await handlePageToShow('/events')
               if (splitQry?.[0] === 'events' && splitQry?.[1] && splitQry?.[2]){
                   // http://localhost:3001/events,1000234,650821b0fe53f10ef87fcbb5
                   await new Promise(r => setTimeout(r, 2000));
                   const obj = {
                       dn: splitQry?.[1],
                       _id: splitQry?.[2]
                   }
                   await handleEvent(obj)
               }
           } else if(splitQry?.[0] === 'media' ){
               await handlePageToShow('/media')

               if (splitQry?.[0] === 'media' && splitQry?.[1] && splitQry?.[2]){
                   // http://localhost:3001/media,1000924,1000924_64ccc9d39cabb75f40f45f85_hyper
                   await new Promise(r => setTimeout(r, 2000));
                   const obj = {
                       dn: splitQry?.[1],
                       file: splitQry?.[2]
                   }
                   const event = await fetchEventsVideoFile(obj)

                   if (event?.length > 0){
                       await handleEvent(event[0])
                       // / http://localhost:3001/media,1005114,1005114_20230908071650-20230908071710_1_25_h265
                   } else {
                       await handleVideoToShow({file: splitQry?.[2]})
                   }
               }
           } else if (splitQry?.[0] === 'livestream' ){
               await new Promise(r => setTimeout(r, 2000));
               if (device[0].properties.drivingStatus !== 'parked'){
                   handleLivestream(device[0])
               }

           } else if (splitQry?.[0] === 'notifications'){
               await new Promise(r => setTimeout(r, 0));
               await handlePageToShow('/notifications')
           }
        urlQuery = null;

        } else if(r?.features?.length === 1) {
            await handleVehicle(r.features[0])
        }
    }

    const closeModal = (name) => {
        modalFunctionMap[`${name}`](false);
        // handleRecenterMap(true);
        if (name === 'displayEventClickModal'){
            setEvent(null)
        }
    }


    let timeout = useRef();
    let jInterval = useRef();

    const setJourneyInterval = async (id) => {
        clearInterval(jInterval?.current)
        clearTimeout(timeout.current);

        var toExactMinute = 60000 - (new Date().getTime() % 60000);

        timeout.current = setTimeout(async () => {
            await refreshJ(id);
            jInterval.current = setInterval(async () => {
                await refreshJ(id);
            }, 60000)
        }, toExactMinute)

    }


    const refreshJ = async (id) => {

        const r = await FetchJourney(id);
        const r2 = await fetchReverseGeocode(r.features[0].geometry.coordinates[1], r.features[0].geometry.coordinates[0]);
        r.details.startMarkerLocation = r2?.features?.[0]?.properties?.address;
        const r3 = await fetchReverseGeocode(r.features[(r.features.length -1)].geometry.coordinates[1],
            r.features[(r.features.length -1)].geometry.coordinates[0])
        if (r3 && r3.features && r.features[0]){
            r.details.endMarkerLocation = r3.features[0].properties.address;
        }


        if(r?.details?.duration === null){
            const start = new Date(r.features[0].properties.time);
            const end = new Date(r.features[(r.features.length - 1)].properties.time);
            const diff = end-start;
            r.details.duration = new Date(diff).toISOString().split('T')[1].slice(0,8);
        }


        setLastJourney(r);
        setJourney(r);


    }





    const handleJourney = async (id) => {

        clearInterval(jInterval?.current)
        clearTimeout(timeout.current);


        hideAllModals();



        if (id){

            const r = await FetchJourney(id);

            if (journey === 'undefined'){
                setLastJourney(r);
            } else {
                setLastJourney(journey);
            }

            r.details.drivingStatus = vehicle?.properties?.drivingStatus


            setEvent(null);//asynchronous

            if (r?.features?.[0]?.geometry){
                fetchReverseGeocode(r.features[0].geometry.coordinates[1], r.features[0].geometry.coordinates[0]).then(r2 => {
                    r.details.startMarkerLocation = r2?.features?.[0]?.properties?.address;
                });
            }

            if (r?.features?.[(r.features.length -1)]?.geometry){
                fetchReverseGeocode(r.features[(r.features.length -1)].geometry.coordinates[1],
                      r.features[(r.features.length -1)].geometry.coordinates[0]).then(r3 => {
                    if (r3 && r3.features && r.features[0]){
                        r.details.endMarkerLocation = r3?.features?.[0]?.properties?.address;
                    }

                });
            }

            if(r?.details?.duration === null){
                const start = new Date(r.features[0].properties.time);
                const end = new Date(r.features[(r.features.length - 1)].properties.time);
                const diff = end-start;
                r.details.duration = new Date(diff).toISOString().split('T')[1].slice(0,8);
            }

            setJourney(r);


            if (r?.details?.inProgress && id === journeys[journeys?.length - 1]?._id){
                await setJourneyInterval(id);
            }

        }
    };


    const handleDate = (e, dayShift = 0) => {
        let nd = new Date(e);
        nd.setDate(e.getDate() + dayShift)

        if(nd > new Date()) {
            return;
        }
        setDate(nd);
    }

    const handleVehicle = async (v) => {


        setJourney(null);
        setVehicle( v?.properties?.dn === vehicle?.properties?.dn ? null : v);

        setEvent(null);

        if (winWidth < 800 && showSidebar ){
            handleShowSidebar(false)
        }

        hideAllModals();
        if (v){

            const details = await fetchDeviceDetails(v)

            if (details[0]?.cameras?.length > 0){

                details[0].latestSnapshots = await fetchLastestSnapshot({
                    ch: details[0]?.cameras[0]?.channel,
                    limit: 5,
                    dn: v.properties.dn
                });
            }
            setDeviceDetails(details[0]);
            try {
                const calendarRaw = await fetchCalendar(v)
                setCalendar(calendarRaw)
            } catch (e) {

            }

        } else {
            setDeviceDetails(null);
            setCalendar(null);
        }

    }



    const refreshEvent = (e) => {
        fetchEventById({dn:e.dn, id: e._id}).then(r => {
            fetchReverseGeocode(r[0]?.lat, r[0]?.lng).then(data => {
                r[0].location = data;
            });
            setEvent(r[0]);//asynchronous
        });
    }

    const handleEvent = async (e) => {
        await refreshEvent(e);
        openModal('displayEventClickModal');
    }




    const handleLivestream = (d) => {
        openModal('displayLivestreamModal');
    }

    const sideBarWidth = {
        marginLeft: sideBarWidthPx
    }


    useEffect(async () => {

        if(vehicle !== null) {

            const r = await fetchJourneys(vehicle.properties.dn, dateString(date));
            if (r) {
                if (!showShortJourneys){
                    await setJourneys(r.filter((j, i) => {
                        if (j.minDistance && i < r.length -1){
                            return true
                        } else if (i === r.length - 1){
                            return true
                        }
                    }))
                } else {
                    await setJourneys(r);
                }
            } else {
                setJourneys(null);
                setJourney(null);
            }
            // const eles = document.getElementsByClassName("mapboxgl-ctrl-top-right");
            // if (eles?.length > 0){
            //     eles[0].style.top="110px";
            // }
        } else {
            // const eles = document.getElementsByClassName("mapboxgl-ctrl-top-right");
            // if (eles?.length > 0) {
            //     eles[0].style.top = "0";
            // }
            setJourneys(null);
            setJourney(null);
            clearInterval(jInterval?.current)

        }
    }, [date, vehicle, showShortJourneys]);



    useEffect(async () => {

        clearInterval(jInterval?.current);

        if (journeys?.length > 0) {
            const id = journeys[journeys?.length - 1]?._id
            await handleJourney(id);
        } else {
            setJourney(null)
        }
    }, [journeys])



    useEffect( () => {


        refreshVehicles()

        if (isStarWars()){
            setTimeout(() => {
                handleShowSplashScreen();
            }, 1000)
        } else {
            setTimeout(() => handleShowSplashScreen(), 1000)
        }

        return () => {
            clearInterval(interval);
        }

    }, []);




    useEffect(() => {
        localStorage.removeItem('hyperlapseFlag')

        hideAllModals()

        if(pageToShow === '/map'){
            clearInterval(window?.notificationsInterval);

            // if (vehicle){
            //     const eles = document.getElementsByClassName("mapboxgl-ctrl-top-right");
            //     if (eles?.length > 0){
            //         eles[0].style.top="110px";
            //         // const eles2 = document.getElementsByClassName("mapboxgl-ctrl-top-left");
            //         // eles2[0].style.top="110px";
            //     }
            // }

        }

        if (pageToShow === '/reports'){
            handleShowSidebar(false)
        } else if (winWidth > 800){
            setTimeout(() => handleShowSidebar(true), 500)
        }

    }, [pageToShow])


    useEffect(() => {
        if (vehicles){
            if(showSidebar) {
                setSideBarWidthPx('0px');
            } else {
                setSideBarWidthPx('-300px');
            }
        }
    }, [showSidebar])


    const handlers = useSwipeable({
        onSwipedLeft: (e) => {handleShowSidebar(false);},
        onSwipedRight: (e) => {handleShowSidebar(true);}
    });




    return (
        <mainContext.Provider value={{vehiclesFilter, vehicle, vehicles, journey, event, handleVehicle, handleJourney,
            handleEvent, sideBarWidthPx, openModal, closeModal, lastMapCenter,
            setLastMapCenter, lastJourney, handleDate, date, handleLivestream,
            fleets, pageToShow,  sideBarWidth, deviceDetails,
            calendar, disableDateChange, handlePageToShow,
            journeys, fleetsToShow, handleTrueLocation, handleDisableDateChange,
            handleVideoToShow, handleFleetsToShow, handleRefreshNotifications,
            dateRange, reportType, handleDateRange, handleReportType,
             displayStoppedClickModal,displayEventClickModal,
            displayTrackClickModal, displayLivestreamModal, trueLocation,
            displayDeviceDetailsModal, displayVideoModal, videoToShow, refreshEvent,
            refreshVehicles, handleDeviceDetails, handleVehicleFilter, handleVehicleFilterParams, vehicleFilterParams,
            toast}}
        >

        <div className={winWidth > 800 ? 'container' : 'container__mobile'}>

            {showSidebar && winWidth < 800 &&
                <Overlay />
            }

            <aside id="sidebar" className={winWidth > 800 ? "container__sidebar hideOnPrint" : "container__sidebar__mobile hideOnPrint"}
                   style={sideBarWidth} {...handlers}>

                <Aside />

                {winWidth < 800 &&
                    <button className="sideBarButton"
                            {...handlers}
                            onClick={e => {
                                e.stopPropagation();
                                handleShowSidebar(!showSidebar);
                            }}>
                        Vehicles
                    </button>
                }
            </aside>


                <main className={winWidth < 800 ? "container__main__mobile bg-primary-reverse text-color" :
                    "container__main bg-primary-reverse text-color"} id="container__main" >

                    {pageToShow === '/map' &&
                        <Map2 />
                    }

                    {(pageToShow === '/media' || pageToShow === '/events' || pageToShow === '/notifications') &&
                        <Grid />
                    }

                    {pageToShow === '/reports' &&
                        <Reports />
                    }

                    {pageToShow === '/places' &&
                        <Places />
                    }

                    <MainModalParent/>

                </main>

            <Toast ref={toast} appendTo={null} position="bottom-right"/>
            </div>
        </mainContext.Provider>
    );
}


export default MainParent;
